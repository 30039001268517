// formatDate.js
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export function formatDate(date) {
  const now = new Date();
  const today = format(now, 'd MMM', { locale: ru });
  const yesterday = format(new Date(now.setDate(now.getDate() - 1)), 'd MMM', { locale: ru });
  const taskDate = format(date, 'd MMM', { locale: ru });

  if (taskDate === today) {
    return `сегодня, ${format(date, 'HH:mm', { locale: ru })}`;
  } else if (taskDate === yesterday) {
    return `вчера, ${format(date, 'HH:mm', { locale: ru })}`;
  } else {
    return format(date, 'd MMM, HH:mm', { locale: ru });
  }
}
