import React from 'react';
import ReactECharts from 'echarts-for-react';

const PointsChart = ({ data }) => {
  // Проверка наличия данных для круговой диаграммы и основной диаграммы
  if (!data || !data.points_summary || data.points_summary.length === 0 || !data.totals) {
    return <div>Нет данных для отображения графика</div>;
  }

  // Список пользователей с их метками
  const users = data.totals[0].users_totals;

  // Сгенерируем цвета для пользователей
  const userColors = {};
  users.forEach((user, index) => {
    const colors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];
    userColors[user.name] = colors[index % colors.length];
  });

  // Данные для круговой диаграммы общего бюджета каждого пользователя
  const commonBudgetOption = {
    tooltip: { trigger: 'item' },
    legend: { orient: 'vertical', left: 'left' },
    series: [
      {
        name: 'Общий вклад в бюджет',
        type: 'pie',
        radius: ['50%', '80%'], // Увеличение радиуса для большей ширины круга
        label: {
          show: true,
          formatter: '{b}: {d}%', // Отображение имени и процента
          fontSize: 14, // Увеличение размера шрифта для лучшей читабельности
          position: 'inside' // Размещение подписей внутри круга
        },
        labelLine: {
          show: false, // Убираем выноски для подписей
        },
        data: users.map(user => ({
          value: user.total_common_budget,
          name: user.name,
          itemStyle: { color: userColors[user.name] }, // Применяем синхронизированный цвет
        })),
      },
    ],
  };

  // Настройки для основного графика без легенды
  const pointsSummaryOption = {
    tooltip: { trigger: 'axis' },
    xAxis: { type: 'category', data: data.points_summary.map(d => d.day) },
    yAxis: { type: 'value' },
    series: Object.keys(data.points_summary[0])
      .filter(key => key !== 'day')
      .map(key => ({
        name: key,
        type: 'bar',
        stack: 'total',
        data: data.points_summary.map(d => d[key]),
        itemStyle: {
          color: userColors[key] || '#cccccc' // Используем цвет из userColors или дефолтный цвет
        }
      })),
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
        <ReactECharts option={commonBudgetOption} style={{ height: 500, width: '60%' }} />
      </div>
      <ReactECharts option={pointsSummaryOption} style={{ height: 400, width: '100%' }} />
    </div>
  );
};

export default PointsChart;
