// set NODE_OPTIONS=--openssl-legacy-provider

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './App.css';
//import { format } from 'date-fns';
//import { ru } from 'date-fns/locale';
import { formatDate } from './formatDate';

import PointsChart from './PointsChart'; // Подключаем график ECharts



function App() {
  const [groupCompletedTasks, setGroupCompletedTasks] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [password, setPassword] = useState('');
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [totals, setTotals] = useState(null);
  const [activePage, setActivePage] = useState('markTasks');
  const [expandedDays, setExpandedDays] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTaskDesc, setCurrentTaskDesc] = useState('');
  const [transformedPointsSummary, setTransformedPointsSummary] = useState(null);
  const [challenges, setChallenges] = useState([]);
  const [activeChallengeId, setActiveChallengeId] = useState(null);
  const [isChallengeLoaded, setIsChallengeLoaded] = useState(false); // Отслеживаем загрузку челленджа

  // Загрузка челленджей
  const loadChallenges = async () => {
    try {
      const response = await axios.get('https://gabbits.ryuzh.online/api/user_challenges', {
        withCredentials: true,
      });
      setChallenges(response.data);

      // Устанавливаем активный челлендж, если он есть
      const activeChallenge = response.data.find((challenge) => challenge.is_active_challenge);
      if (activeChallenge) {
        setActiveChallengeId(activeChallenge.challenge_id);
      }

      setIsChallengeLoaded(true); // Помечаем, что челлендж загружен
    } catch (error) {
      console.error('Ошибка при загрузке челленджей:', error);
    }
  };

  const getChallengeId = () => {
    if (!activeChallengeId) {
      loadChallenges();
    }
    return activeChallengeId;
  };

  const updateChallenge = async (newChallengeId) => {
    try {
      await axios.patch(
        'https://gabbits.ryuzh.online/api/user_challenges',
        { challenge_id: newChallengeId },
        { withCredentials: true }
      );
      setActiveChallengeId(newChallengeId);
      loadChallenges();
      setActivePage('markTasks');
      loadTasks();
    } catch (error) {
      console.error('Ошибка при обновлении активного челленджа:', error);
    }
  };

  // Обернутые функции
// Функции без useCallback
const loadTasks = useCallback(async () => {
  const challengeId = getChallengeId();
  if (!challengeId) return; // Выходим, если `challenge_id` не удалось получить
  try {
    const response = await axios.get(`https://gabbits.ryuzh.online/api/tasks?challenge_id=${challengeId}`, {
      withCredentials: true,
    });
    setTasks(response.data);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      setIsAuthenticated(false);
      setActivePage('login');
    } else {
      console.error('Ошибка при загрузке дел:', error);
    }
  }
}, [getChallengeId]);

  const loadCompletedTasks = async () => {
  const challengeId = getChallengeId();
  if (!challengeId) return; // Выходим, если `challenge_id` не удалось получить
  try {
    const response = await axios.get(`https://gabbits.ryuzh.online/api/completed-tasks?challenge_id=${challengeId}`, {
      withCredentials: true,
    });
    setCompletedTasks(response.data);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      setIsAuthenticated(false);
      setActivePage('login');
    } else {
      console.error('Ошибка при загрузке выполненных дел:', error);
    }
  }
};

  const loadTotals = async () => {
    const challengeId = getChallengeId();
    if (!challengeId) return; // Выходим, если `challenge_id` не удалось получить
    try {
      const response = await axios.get(`https://gabbits.ryuzh.online/api/tasks-totals?challenge_id=${challengeId}`, {
        withCredentials: true,
      });      
      const data = Array.isArray(response.data) ? response.data[0] : response.data;
      setTotals(data.totals[0]);
      setTransformedPointsSummary(data);
    } catch (error) {
      console.error('Ошибка при загрузке балансов:', error);
    }
  };

  const loadGroupCompletedTasks = async () => {
    const challengeId = getChallengeId();
    if (!challengeId) return; // Выходим, если `challenge_id` не удалось получить
    try {
      const response = await axios.get(`https://gabbits.ryuzh.online/api/group-completed-tasks?challenge_id=${challengeId}`, {
        withCredentials: true,
      });
      if (response.data && response.data.length > 0) {
        setGroupCompletedTasks(response.data[0].response_json);
      } else {
        setGroupCompletedTasks([]);
      }
    } catch (error) {
      console.error('Ошибка при загрузке всех выполненных дел:', error);
      setGroupCompletedTasks([]);
    }
  };

  // Обработчик изменения страницы
  const handlePageChange = (page) => {
    setActivePage(page);
    switch (page) {
      case 'markTasks':
        loadTasks();
        break;
      case 'completedTasks':
        loadCompletedTasks();
        break;
      case 'totals':
        loadTotals();
        break;
      case 'groupCompletedTasks':
        loadGroupCompletedTasks();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadChallenges();
  }, []);
 
  
  // Проверка аутентификации при первом рендере
useEffect(() => {
  // Проверка токена через запрос к серверу
  axios.get('https://gabbits.ryuzh.online/api/check-auth', {
    withCredentials: true  // Включаем отправку куки с запросом
  })
  .then(response => {
    if (response.data.success) {
      setIsAuthenticated(true);  // Устанавливаем, что пользователь авторизован
    } else {
      setIsAuthenticated(false);
      console.error('Пользователь не авторизован.');
      // Переключаем страницу на авторизацию
      setActivePage('login'); // Здесь 'login' — это логическое название вашей страницы авторизации
    }
  })
  .catch(error => {
    console.error('Ошибка проверки авторизации:', error);
    setIsAuthenticated(false);
    // Переключаем страницу на авторизацию в случае ошибки
    setActivePage('login');
  });
}, []);

  //useEffect(() => {
//    loadTotals();
//  }, []);

  // Загружаем пользователей при загрузке компонента
  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (isAuthenticated && isChallengeLoaded && activeChallengeId) {
      loadTasks();
    }
  }, [isAuthenticated, isChallengeLoaded, activeChallengeId, loadTasks]);


  /// Отмена выполнения задачи для всех дел
  const cancelGroupTask = async (donetaskId) => {
    const userConfirmed = window.confirm("Вы действительно хотите отменить задачу?");
    if (!userConfirmed) {
      return;
    }
    const challengeId = getChallengeId();
    if (!challengeId) return; // Выходим, если `challenge_id` не удалось получить

    try {
      await axios.post('https://gabbits.ryuzh.online/api/group-cancel-task', {
        donetask_id: donetaskId,
        challenge_id: challengeId, // Передаем текущий challenge_id
      }, {
        withCredentials: true,
      });

      alert('Выполненное дело успешно отменено!');
      await loadGroupCompletedTasks();  // Обновляем список выполненных дел всех пользователей
    } catch (error) {
      console.error('Ошибка при отмене выполнения задачи:', error);
      alert('Ошибка при отмене выполнения задачи.');
    }
  }; // Закрытие функции cancelGroupTask

  // Функция для смены пароля
const handleChangePassword = async () => {
  if (!password || !newPassword || !confirmNewPassword) {
    alert('Все поля должны быть заполнены.');
    return;
  }

  if (newPassword.length < 8) {
    alert('Новый пароль должен быть не менее 8 символов.');
    return;
  }

  if (newPassword !== confirmNewPassword) {
    alert('Новые пароли не совпадают.');
    return;
  }

  if (newPassword === password) {
    alert('Ваш новый пароль такой же, как старый.');
    return;
  }

  try {
    const response = await axios.post('https://gabbits.ryuzh.online/api/change_password', {
      currentPassword: password,
      newPassword: newPassword
    }, {
      withCredentials: true
    });

    if (response.status === 200) {
      alert('Пароль успешно изменён.');
      setPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } else {
      alert('Ошибка при смене пароля.');
    }

  } catch (error) {
    console.error('Ошибка при смене пароля:', error);
    if (error.response && error.response.data && error.response.data.error) {
      alert(`Ошибка при смене пароля: ${error.response.data.error}`);
    } else {
      alert('Ошибка при смене пароля.');
    }
  }
};


  // Функция для загрузки списка пользователей
  const loadUsers = async () => {
    try {
      const response = await axios.get('https://gabbits.ryuzh.online/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
    }
  };

  // Авторизация пользователя
  const handleLogin = async () => {
    try {
      const response = await axios.post('https://gabbits.ryuzh.online/api/login', {
        userId: selectedUserId,
        password
      }, {
        withCredentials: true  // Включаем отправку куки с запросом
      });

      if (response.data.success) {
        setIsAuthenticated(true);  // Пользователь авторизован
      }
    } catch (error) {
      console.error('Ошибка при авторизации:', error);
      alert('Неверный пароль или ошибка авторизации.');
    }
  };


 


  // Выход из системы
  const handleLogout = async () => {
    try {
      await axios.post('https://gabbits.ryuzh.online/api/logout', {}, {
        withCredentials: true  // Включаем отправку куки с запросом
      });
      setIsAuthenticated(false);
      setPassword('');
      setSelectedUserId('');
    } catch (error) {
      console.error('Ошибка при выходе:', error);
    }
  };

  
  // Отметить задачу как выполненную
const completeTask = async (taskId, commonValue, personalValue, task) => {
  // Ожидаем подтверждение пользователя через popup
  const userConfirmed = window.confirm("Вы уверены, что хотите отметить задачу как выполненную?");
  if (!userConfirmed) {
    return; // Прекращаем выполнение, если пользователь отменил действие
  }

  // Устанавливаем состояние загрузки
  setIsLoading(true);
  const challengeId = getChallengeId();
  if (!challengeId) return; // Выходим, если `challenge_id` не удалось получить

  try {
    // Отправляем запрос на выполнение задачи
    await axios.post('https://gabbits.ryuzh.online/api/complete-task', {
      taskId,
      commonValue,
      personalValue,
      task,
      challenge_id: challengeId, // Добавляем текущий challenge_id
    }, {
      withCredentials: true  // Включаем отправку куки с запросом
    });
  

    // Сообщение об успешном выполнении задачи
    alert('Задача выполнена!');

    // Обновляем список задач после выполнения
    await loadTasks();  // Перезагрузка списка задач
    await loadCompletedTasks();  // Обновляем выполненные задачи

  } catch (error) {
    console.error('Ошибка при выполнении задачи:', error);
  } finally {
    // Отключаем состояние загрузки после завершения
    setIsLoading(false);
  }
};

    const cancelTask = async (donetaskId) => {
    const userConfirmed = window.confirm("Вы действительно хотите отменить задачу?");
    if (!userConfirmed) {
      return;
    }

    const challengeId = getChallengeId();
    if (!challengeId) return; // Выходим, если `challenge_id` не удалось получить

    try {
      await axios.post('https://gabbits.ryuzh.online/api/cancel-task', {
        donetask_id: donetaskId,
        challenge_id: getChallengeId, // Передаем текущий challenge_id
      }, {
        withCredentials: true
      });

      alert('Выполненное дело успешно отменено!');
      await loadCompletedTasks();  // Обновляем список выполненных задач
    } catch (error) {
      console.error('Ошибка при отмене выполнения задачи:', error);
      alert('Ошибка при отмене выполнения задачи.');
    }
  };

  // Функция для переключения развернутого состояния дня
   const toggleDay = (date) => {
    setExpandedDays(prevState => ({
      ...prevState,
      [date]: !prevState[date]
    }));
  };
  
  // Функция для переключения развернутого состояния пользователя
  const toggleUser = (userId) => {
    setExpandedDays((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };
  
  
     const openModal = (desc) => {
      setCurrentTaskDesc(desc);  // Устанавливаем описание задачи
      setIsModalOpen(true);  // Открываем модальное окно
    };
    
    const closeModal = () => {
      setIsModalOpen(false);  // Закрываем модальное окно
    };



  // Страницы
  // Страница для отображения списка задач
    const renderMarkTasksPage = () => (
  <div>
    <h1>Список дел</h1>
    <ul>
      {tasks.map(task => (
        <li key={task.task_id} className="task-item">
          {task.is_new && (
            <div className="new-label">
              новое
            </div>
          )}
          <strong>{task.task}</strong>
          {/* Иконка для вывода описания задачи */}
          {task.desc && (
            <span className="info-icon" onClick={() => openModal(task.desc)}> ℹ️ </span>
          )}
          <br />
          В общий бюджет: {task.family_points} <br />
          В личный бюджет: {task.personal_points} <br />
          {task.last_time ? (
            <>Последнее выполнение: {formatDate(new Date(task.last_time))} <br /></>
          ) : null}
          <button onClick={() => completeTask(task.task_id, task.family_points, task.personal_points, task.task)} disabled={isLoading}>
            {isLoading ? 'Загрузка...' : 'Отметить как выполненное'}
          </button>
        </li>
      ))}
    </ul>

    {/* Модальное окно для вывода task.desc */}
    {isModalOpen && (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>&times;</span>
          <p>{currentTaskDesc}</p>
        </div>
      </div>
    )}
  </div>
);



    const renderChangePasswordPage = () => (
  <div>
    <h1>Смена пароля</h1>
    <input
      type="password"
      placeholder="Введите текущий пароль"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    />
    <input
      type="password"
      placeholder="Введите новый пароль (не менее 8 символов)"
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
    />
    <input
      type="password"
      placeholder="Повторите новый пароль"
      value={confirmNewPassword}
      onChange={(e) => setConfirmNewPassword(e.target.value)}
    />
    <button onClick={handleChangePassword}>Сменить пароль</button>
  </div>
);




const renderCompletedTasksPage = () => (
  <div>
    <h1>Мои выполненные дела:</h1>
    {completedTasks.map((participant) => (
      <div key={participant.participant_id}>
        <h2>{participant.participant}</h2>
        {participant.response_json.map((day) => (
          <div key={day.date} className="completed-day">
          <h3 onClick={() => toggleDay(day.date)}>
            {day.date} {expandedDays[day.date] ? '▼' : '►'}
          </h3>
          <p className="points">
            Семейные баллы: {day.total_family_points || 0} <br />
            Личные баллы: {day.total_personal_points || 0}
          </p>            
            {expandedDays[day.date] && (
              <ul>
                {day.tasks.map((task) => (
                  <li key={task.donetask_id}>
                    <strong>{task.task}</strong><br />
                    Семейные баллы: {task.family_points || 0} <br />
                    Личные баллы: {task.personal_points || 0}<br />
                    {task.exact_datetime ? (
                      <>Время выполнения: {formatDate(new Date(task.exact_datetime))} <br /></>
                    ) : null}
                    <button onClick={() => cancelTask(task.donetask_id)}>Отменить выполнение</button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    ))}
  </div>
);


  
  const renderTotalsPage = () => (
    <div className="totals-container">
      <h1>Баланс счетов команды</h1>
      {totals ? (
        <div className="totals-summary">
          <h2>Итоговые результаты команды:</h2>
          <p><strong>Общий бюджет команды:</strong> {totals.total_common_budget} балл(ов)</p>
          <p><strong>Всего выполнено задач:</strong> {totals.total_completed_tasks}</p>
          
          {/* Добавляем график с преобразованными данными */}
          <h2>Баллы за последние 7 дней</h2>
          {transformedPointsSummary && <PointsChart data={transformedPointsSummary} />}
  
          <h2>Детали по каждому участнику:</h2>
          <div className="user-totals">
            {totals.users_totals && totals.users_totals.map(user => (
              <div key={user.user_id} className="user-card">
                <h3>{user.name}</h3>
                <p><strong>Выполнено задач:</strong> {user.completed_tasks_count}</p>
                <p><strong>Вклад в общий бюджет:</strong> {user.total_common_budget} балл(ов)</p>
                <p><strong>Личный счет:</strong> {user.total_personal_budget} балл(ов)</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>Загрузка...</p>
      )}
    </div>
  );
  
  
  // Страница для отображения всех выполненных дел всех пользователей
const renderGroupCompletedTasksPage = () => (
  <div>
    <h1>Выполненные дела команды за последние сутки:</h1>
    {groupCompletedTasks.length > 0 ? (
      groupCompletedTasks.map((user) => (
        <div key={user.user_id}>
          <h2 onClick={() => toggleUser(user.user_id)}>{user.user} {expandedDays[user.user_id] ? '▼' : '►'}</h2>
          <p className="points">
            Семейные: {user.total_family_points} <br />
            Личные: {user.total_personal_points}
          </p>
          {expandedDays[user.user_id] && (
            <ul>
              {user.tasks.map((task) => (
                <li key={task.donetask_id}>
                  <strong>{task.task}</strong><br />
                  Семейные баллы: {task.family_points}<br />
                  Личные баллы: {task.personal_points}<br />
                  {task.exact_datetime ? (
                    <>Время выполнения: {formatDate(new Date(task.exact_datetime))} <br /></>
                  ) : null}
                  <button onClick={() => cancelGroupTask(task.donetask_id)}>Отменить выполнение</button>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))
    ) : (
      <p>Нет выполненных дел за последние сутки.</p>
    )}
  </div>
);

 

  if (!isAuthenticated) {
    return (
      <div className="container">
        <h1>Авторизация</h1>

        {/* Если пользователи ещё загружаются или список пуст, показываем сообщение */}
        {users.length === 0 ? (
          <p>Загрузка пользователей...</p>
        ) : (
          <select onChange={(e) => setSelectedUserId(e.target.value)} value={selectedUserId}>
            <option value="">Выберите пользователя</option>
            {users.map(user => (
              <option key={user.id} value={user.id}>{user.title || 'Без имени'}</option>
            ))}
          </select>
        )}

        <input
          type="password"
          placeholder="Введите пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {/* Проверка перед вызовом handleLogin */}
        <button onClick={() => {
          if (!selectedUserId || !password) {
            alert('Пожалуйста, выберите пользователя и введите пароль');
          } else {
            handleLogin();
          }
        }}>
          Войти
        </button>
      </div>
    );
  }

  return (
    <div className="container">
      <h1>{challenges.length > 1 ? "Выберите активный челлендж" : "Текущий челлендж"}</h1>

      {/* Проверка, если челленджей больше одного, показываем droplist */}
      {challenges.length > 1 ? (
        <select
          value={activeChallengeId || ''}
          onChange={(e) => updateChallenge(Number(e.target.value))}
        >
          <option value="" disabled>Выберите челлендж</option>
          {challenges.map((challenge) => (
            <option key={challenge.challenge_id} value={challenge.challenge_id}>
              {challenge.challenge}
            </option>
          ))}
        </select>
      ) : (
        // Если только один челлендж, просто отображаем его название
        <p>{challenges[0]?.challenge}</p>
      )}


      {/* Навигация по страницам */}
      <div className="navigation">
        <button onClick={() => handlePageChange('markTasks')}>Отметить выполнение</button>
        <button onClick={() => handlePageChange('completedTasks')}>Мои выполненные дела</button>
        <button onClick={() => handlePageChange('totals')}>Баланс команды</button>
        <button onClick={() => handlePageChange('groupCompletedTasks')}>Последние дела команды</button>
        <button onClick={() => handlePageChange('changePassword')}>Сменить пароль</button>
        <button onClick={handleLogout}>Выйти</button>
      </div>

      {/* Отображение текущей страницы */}
      {activePage === 'markTasks' && renderMarkTasksPage()}
      {activePage === 'completedTasks' && renderCompletedTasksPage()}
      {activePage === 'groupCompletedTasks' && renderGroupCompletedTasksPage()}
      {activePage === 'totals' && renderTotalsPage()}
      {activePage === 'changePassword' && renderChangePasswordPage()}
    </div>
  );
}


export default App;